var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}],staticClass:"pa-2",attrs:{"tile":""}},[_c('CardTitle',{attrs:{"icon":"pi-task-run"}},[_c('div',{staticClass:"text-truncate pb-1",attrs:{"slot":_vm.$vuetify.breakpoint.lgAndUp && 'title'},slot:_vm.$vuetify.breakpoint.lgAndUp && 'title'},[_vm._v(" "+_vm._s(_vm.tableTitle)+" ")]),_c('div',{class:{ 'd-flex': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"slot":_vm.$vuetify.breakpoint.mdAndDown ? 'title' : 'state-filter'},slot:_vm.$vuetify.breakpoint.mdAndDown ? 'title' : 'state-filter'},[_c('v-select',{staticClass:"state-filter",style:([
          _vm.$vuetify.breakpoint.mdAndUp ? { width: '280px' } : { width: '100%' }
        ]),attrs:{"data-public":"","outlined":"","dense":"","flat":"","solo":"","hide-details":"","menu-props":{ top: true, offsetY: true },"clearable":"","items":_vm.states,"label":"Filter by state","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(index === 0 || index === 1)?_c('v-chip',{attrs:{"color":item,"label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(item)+" ")]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.state.length - 2)+") ")]):_vm._e()]}}]),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}),_c('v-text-field',{staticClass:"task-search",staticStyle:{"min-width":"210px"},attrs:{"slot":"action","dense":"","flat":"","solo":"","prepend-inner-icon":"search","hide-details":"","placeholder":"Search by Task Name"},slot:"action",model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),_c('v-card-text',[_c('v-data-table',{staticClass:"truncate-table",class:{ 'fixed-table': _vm.$vuetify.breakpoint.smAndUp },attrs:{"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 15, 25, 50],
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right'
      },"headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.flowRun ? _vm.flowRun.task_runs : [] || [],"items-per-page":_vm.itemsPerPage,"loading":_vm.$apollo.queries.flowRun.loading,"must-sort":"","page":_vm.page,"server-items-length":_vm.taskRunsCount,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"calculate-widths":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.task.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('router-link',{staticClass:"link text-truncate",attrs:{"data-cy":'task-run-table-link|' + item.task.name,"to":{ name: 'task-run', params: { id: item.id } }}},[(item.name)?_c('span',_vm._g({},on),[_vm._v(_vm._s(item.name))]):_c('span',_vm._g({},on),[_vm._v(_vm._s(item.task.name)),(item.map_index > -1)?_c('span',[_vm._v(" (Mapped Child "+_vm._s(item.map_index)+")")]):(_vm.hasChild(item.task.name))?_c('span',[_vm._v(" (Parent) ")]):_vm._e()])])]}}],null,true)},[(item.name)?_c('span',[_vm._v(_vm._s(_vm.flowRun.name)+" - "+_vm._s(item.task.name)+" - "+_vm._s(item.name))]):_c('span',[_vm._v(" "+_vm._s(_vm.flowRun.name)+" - "+_vm._s(item.task.name)),(item.map_index > -1)?_c('span',[_vm._v(" (Mapped Child "+_vm._s(item.map_index)+")")]):(_vm.hasChild(item.task.name))?_c('span',[_vm._v(" (Parent) ")]):_vm._e()])])],1)]}},{key:"item.start_time",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":_vm.formatTime(item.start_time)}},[_vm._v(" "+_vm._s(_vm.formDate(item.start_time))+" ")])]}},{key:"item.end_time",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":_vm.formatTime(item.end_time)}},[_vm._v(" "+_vm._s(_vm.formDate(item.end_time))+" ")])]}},{key:"item.duration",fn:function(ref){
      var item = ref.item;
return [(item.start_time)?_c('DurationSpan',{attrs:{"start-time":item.start_time,"end-time":_vm.calculateDuration(item.start_time, item.end_time, item.state)}}):_c('span',[_vm._v("...")])]}},{key:"item.state",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":item.state}},[_c('v-icon',{staticClass:"mr-1 pointer",attrs:{"small":"","color":item.state}},[_vm._v(" brightness_1 ")])],1)]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [(item.state == 'Paused')?_c('ResumeButton',{attrs:{"task-run":Object.assign({}, item, {flow_run: _vm.flowRun}),"dialog-type":"resume"}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }